import React from "react";
import { Breadcrumb as AntDBreadcrumb } from "antd";
import { CodeOutlined, DashboardOutlined, LeftOutlined, SettingOutlined } from "@ant-design/icons";
import { useLocation, useParams } from "react-router-dom";

const Breadcrumb = () => {
  const { frontEndAppId } = useParams();
  const { pathname } = useLocation();

  return (
    <div className="px-6 border-b border-light flex items-center w-full h-10">
      <AntDBreadcrumb
        items={[
          {
            href: "/apps",
            title: (
              <div className="flex items-center hover:text-sky-600">
                <LeftOutlined />
                <span className="!ml-2">Back</span>
              </div>
            ),
          },
          {
            href: `/apps/${frontEndAppId}/overview`,
            title: (
              <div
                className={`flex items-center hover:text-sky-600 ${
                  pathname.endsWith("overview") ? "text-sky-600" : null
                }`}
              >
                <DashboardOutlined />
                <span className="!ml-2">Overview</span>
              </div>
            ),
          },
          {
            href: `/apps/${frontEndAppId}`,
            title: (
              <div
                className={`flex items-center hover:text-sky-600 ${
                  pathname.endsWith(frontEndAppId) ? "text-sky-600" : null
                }`}
              >
                <SettingOutlined />
                <span className="!ml-2">Configs</span>
              </div>
            ),
          },
          {
            href: `/apps/${frontEndAppId}/integrations`,
            title: (
              <div
                className={`flex items-center hover:text-sky-600 ${
                  pathname.endsWith("integrations") ? "text-sky-600" : null
                }`}
              >
                <CodeOutlined />
                <span className="!ml-2">API</span>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

export default Breadcrumb;
