import React from "react";

const UserMsg = ({ message }) => {
  return (
    <div className="flex justify-end">
      <div
        className="px-4 py-2 rounded-xl rounded-br-none mb-6 text-white user-msg"
        style={{ maxWidth: "70%", backgroundColor: "#1677ff" }}
      >
        {message}
      </div>
    </div>
  );
};

export default UserMsg;
