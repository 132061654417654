import { ApiOutlined, FileTextOutlined } from "@ant-design/icons";
import { Button, Card, Select, Switch, Typography, message } from "antd";
import { BsShare, BsRocket } from "react-icons/bs";
import React, { useEffect } from "react";

import LineChart from "features/analytics/line-chart";
import { useNavigate, useParams } from "react-router-dom";
import { fetchApp, setApiEnabled, setPreviewEnabled } from "store/config-app.reducer";
import { useDispatch, useSelector } from "react-redux";
import withTag from "features/ui/app-card/app-card.config";
import Modal from "antd/es/modal/Modal";

const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const { Title } = Typography;

const Overview = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { frontEndAppId } = useParams();

  const { appName, status, type, totalMessages, tokenUsage, totalCost, isApiEnabled, isPreviewEnabled } = useSelector(
    (state) => state.configApp,
  );

  console.log("configApp", isApiEnabled, isPreviewEnabled);

  useEffect(() => {
    dispatch(fetchApp(frontEndAppId)).catch((error) => {
      message.error("Page not found.", 5);
      console.error("Error fetching app", error);
    });
  }, []);

  const onPreviewClick = () => {
    window.open(`/preview/${frontEndAppId}`, "preview", "height=800,width=600");
  };

  return (
    <div className="w-full h-full bg-slate-50 p-6 text-left overflow-y-auto">
      <Title level={3}>Overview</Title>
      {/* <Text type="secondary">Configure existing App or create new one.</Text> */}
      <div className="grid grid-cols-2 gap-7 mb-6">
        <Card bordered={false} className="w-full h-fit justify-between border border-light bg-sky-50">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <div
                className="flex items-center h-10 w-10 justify-center bg-sky-300 rounded-lg text-3xl"
                // style={{ maxHeight: 44 }}
              >
                🤖
                {/* {type === CHAT_APP ? "🤖" : type === TEXT_GENERATOR ? "📓" : "🧐"} */}
              </div>
              <div className="ml-3">
                <div className="text-md font-medium text-slate-600">{appName}</div>
                <div className="flex">
                  {withTag[type]}
                  {withTag[status]}
                </div>
              </div>
            </div>
            <div className="flex items-start">
              {isPreviewEnabled && status === "READY" ? withTag["IN SERVICE"] : withTag["DISABLE"]}
              <Switch
                checked={isPreviewEnabled}
                disabled={status !== "READY" && isPreviewEnabled}
                onChange={(checked) => {
                  dispatch(setPreviewEnabled(checked));
                }}
              />
            </div>
          </div>
          <div className="flex flex-col mt-4">
            <div className="text-xs text-slate-500">Public URL</div>
            <div className="text-slate-700 mb-4">{`${window.origin}/preview/${frontEndAppId}`}</div>

            <div className="flex gap-3">
              <Button
                icon={<BsRocket />}
                className="flex items-center"
                onClick={onPreviewClick}
                disabled={status !== "READY"}
              >
                Preview
              </Button>
              <Button
                icon={<BsShare />}
                className="flex items-center"
                onClick={() => info(`${window.origin}/preview/${frontEndAppId}`)}
                disabled={status !== "READY"}
              >
                Share
              </Button>
            </div>
          </div>
        </Card>
        <Card bordered={false} className="w-full h-fit justify-between border border-light bg-violet-50">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <div className="flex items-center h-10 w-10 justify-center bg-violet-300 text-slate-700 rounded-lg text-2xl">
                <ApiOutlined />
              </div>
              <div className="ml-3">
                <div className="text-md font-medium text-slate-800">Backend API service</div>
                <div className="text-xs text-slate-500 overflow-hidden whitespace-nowrap text-ellipsis">
                  Seamless integration with your application
                </div>
              </div>
            </div>
            <div className="flex items-start">
              {isApiEnabled && status === "READY" ? withTag["IN SERVICE"] : withTag["DISABLE"]}
              <Switch
                checked={isApiEnabled}
                disabled={status !== "READY" && isApiEnabled}
                onChange={(checked) => dispatch(setApiEnabled(checked))}
              />
            </div>
          </div>
          <div className="flex flex-col mt-4">
            <div className="text-xs text-slate-500">API Token</div>
            <div className="text-slate-700 mb-4">{`${REACT_APP_BACKEND_URL}/${frontEndAppId}/chat`}</div>

            <div className="flex gap-3">
              <Button
                icon={<FileTextOutlined />}
                className="flex items-center"
                disabled={status !== "READY"}
                onClick={() => navigate(`/apps/${frontEndAppId}/integrations`)}
              >
                API Reference
              </Button>
            </div>
          </div>
        </Card>
      </div>
      <div className="flex justify-between items-end mb-3">
        <Title level={3}>Analytics</Title>

        <Select
          defaultValue="Last 7 Days"
          size="medium"
          style={{
            width: 120,
          }}
          onChange={(e) => console.log(e)}
          options={[
            {
              value: "last7days",
              label: "Last 7 Days",
            },
            {
              value: "lastWeek",
              label: "Last Week",
            },
            {
              value: "lastMonth",
              label: "Last Month",
            },
          ]}
        />
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3 gap-4">
        <LineChart title="Total Messages" subtitle="Last 7 Days" data={totalMessages} label="Total Messages" />
        <LineChart
          title="User Satisfaction Rate"
          subtitle="Last 7 Days"
          data={[0, 0, 0, 0, 0, 0, 0]}
          label="Satisfaction Rate"
        />
        <LineChart title="Active Users" subtitle="Last 7 Days" data={[0, 0, 0, 0, 0, 0, 0]} label="Active Users" />
        <LineChart
          title="Token Usage"
          subtitle="Last 7 Days"
          data={tokenUsage}
          metadata={totalCost}
          label="Token Usage"
        />
      </div>
    </div>
  );
};

const info = (url) => {
  return Modal.success({
    title: "The shared URL has been copied to your clipboard",
    content: (
      <div>
        <p className="text-xs text-slate-500">Invite others to access the applications by this URL</p>
        <div className="bg-slate-100 p-2 rounded-md text-sky-600">{url}</div>
      </div>
    ),
    onOk() {},
  });
};

export default Overview;
