import React from "react";
import mindlayerLogo from "assets/images/mindlayer-logo.png";
import { Avatar } from "antd";

function Nav() {
  return (
    <div className="flex w-full h-16 justify-between items-center border-b border-light">
      <div className="flex h-full justify-center items-center p-4 w-50">
        <img src={mindlayerLogo} className="object-contain" />
      </div>
      <Avatar size="large" className="bg-indigo-400 mr-3">
        KM
      </Avatar>
    </div>
  );
}

export default Nav;
