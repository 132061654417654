import React from "react";

const IconLabel = ({ icon, label, classname }) => {
  return (
    <div className={`flex gap-2 ${classname}`} style={{ fontSize: 14 }}>
      {icon}
      <div style={{ fontSize: 14 }}>{label}</div>
    </div>
  );
};

export default IconLabel;
