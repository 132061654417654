import { CHAT_APP, TEXT_GENERATOR, REVIEWER } from "constants/app-type";

import { CommentOutlined, EyeOutlined, FileTextOutlined } from "@ant-design/icons";
import { Tag } from "antd";

const withTag = {
  [CHAT_APP]: (
    <Tag icon={<CommentOutlined />} color="processing" className="flex items-center w-fit">
      {CHAT_APP}
    </Tag>
  ),
  [TEXT_GENERATOR]: (
    <Tag icon={<FileTextOutlined />} color="success" className="flex items-center w-fit">
      {TEXT_GENERATOR}
    </Tag>
  ),
  [REVIEWER]: (
    <Tag icon={<EyeOutlined />} color="warning" className="flex items-center w-fit">
      {REVIEWER}
    </Tag>
  ),
  READY: (
    <Tag color="green" className="flex items-center w-fit">
      READY
    </Tag>
  ),
  PENDING: (
    <Tag color="cyan" className="flex items-center w-fit">
      PENDING
    </Tag>
  ),
  "IN PROGRESS": (
    <Tag color="geekblue" className="flex items-center w-fit">
      IN PROGRESS
    </Tag>
  ),
  "IN SERVICE": (
    <Tag color="green" className="flex items-center w-fit">
      In Service
    </Tag>
  ),
  DISABLE: (
    <Tag color="magenta" className="flex items-center w-fit">
      Disable
    </Tag>
  ),
};

export default withTag;
