import { Typography } from "antd";
import React from "react";
import mindlayerLogo from "assets/images/mindlayer-logo.png";
import whatsAppIcon from "assets/icons/whatsapp.png";
import telegram from "assets/icons/telegram.png";
import wechat from "assets/icons/wechat.png";
import teams from "assets/icons/teams.png";

const { Title, Text } = Typography;

const Plugins = () => {
  return (
    <div className="w-full h-full bg-slate-50 p-6 text-left overflow-y-auto">
      <Title level={3}>Plugins</Title>
      <Text type="secondary">Integrate your current application with these plugins</Text>
      <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 gap-4 mt-6">
        <div className="bg-white rounded-lg shadow-md h-40 flex flex-col justify-center items-center hover:scale-105 transition-all hover:shadow-lg cursor-pointer relative plugin">
          <div className="h-2/3 flex justify-center items-center" style={{ width: 200 }}>
            <img src={mindlayerLogo} />
          </div>
          <div className="h-1/3 text-slate-600 text-md flex justify-center items-center">Mindlayer CBP</div>
          <div
            className="flex w-full h-full absolute items-center justify-center text-slate-600 rounded-md invisible"
            style={{ background: "rgba(0, 0, 0, 0.1)" }}
          >
            <span className="-rotate-12">Coming Soon</span>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-md h-40 flex flex-col justify-center items-center hover:scale-105 transition-all hover:shadow-lg cursor-pointer plugin">
          <div className="w-20 h-2/3 flex justify-center items-center">
            <img src={whatsAppIcon} />
          </div>
          <div className="h-1/3 text-slate-600 text-md flex justify-center items-center">WhatsApp</div>
          <div
            className="flex w-full h-full absolute items-center justify-center text-slate-600 rounded-md invisible"
            style={{ background: "rgba(0, 0, 0, 0.1)" }}
          >
            <span className="-rotate-12">Coming Soon</span>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-md h-40 flex flex-col justify-center items-center hover:scale-105 transition-all hover:shadow-lg cursor-pointer plugin">
          <div className="w-16 h-2/3 flex justify-center items-center">
            <img src={telegram} />
          </div>
          <div className="h-1/3 text-slate-600 text-md flex justify-center items-center">Telegram</div>
          <div
            className="flex w-full h-full absolute items-center justify-center text-slate-600 rounded-md invisible"
            style={{ background: "rgba(0, 0, 0, 0.1)" }}
          >
            <span className="-rotate-12">Coming Soon</span>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-md h-40 flex flex-col justify-center items-center hover:scale-105 transition-all hover:shadow-lg cursor-pointer plugin">
          <div className="w-16 h-2/3 flex justify-center items-center">
            <img src={wechat} />
          </div>
          <div className="h-1/3 text-slate-600 text-md flex justify-center items-center">WeChat</div>
          <div
            className="flex w-full h-full absolute items-center justify-center text-slate-600 rounded-md invisible"
            style={{ background: "rgba(0, 0, 0, 0.1)" }}
          >
            <span className="-rotate-12">Coming Soon</span>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-md h-40 flex flex-col justify-center items-center hover:scale-105 transition-all hover:shadow-lg cursor-pointer plugin">
          <div className="w-16 h-2/3 flex justify-center items-center">
            <img src={teams} />
          </div>
          <div className="h-1/3 text-slate-600 text-md flex justify-center items-center">Teams</div>
          <div
            className="flex w-full h-full absolute items-center justify-center text-slate-600 rounded-md invisible"
            style={{ background: "rgba(0, 0, 0, 0.1)" }}
          >
            <span className="-rotate-12">Coming Soon</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Plugins;
