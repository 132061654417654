const initialState = {
  toneOfResponse: "Custom",
  toneSetting: {
    temperature: {
      label: "Temperature",
      max: 2,
      min: 0,
      step: 0.1,
      value: 1,
      qTipTitle:
        "Controls randomness: Lowering results in less random completions. As the temperature approaches zero, the model will become deterministic and repetitive.",
    },
    topP: {
      label: "Top P",
      max: 1,
      min: 0,
      step: 0.1,
      value: 0.5,
      qTipTitle:
        "Controls diversity via nucleus sampling: 0.5 means half of all likelihood-weighted options are considered.",
    },
    presencePenalty: {
      label: "Presence penalty",
      max: 2,
      min: -2,
      step: 0.1,
      value: 0,
      qTipTitle:
        "How much to penalize new tokens based on whether they appear in the text so far. Increases the model's likelihood to talk about new topics.",
    },
    frequencyPenalty: {
      label: "Frequency penalty",
      max: 2,
      min: -2,
      step: 0.1,
      value: 0,
      qTipTitle:
        "How much to penalize new tokens based on their existing frequency in the text so far. Decreases the model's likelihood to repeat the same line verbatim.",
    },
    maxToken: {
      label: "Max token",
      max: 4000,
      min: 0,
      step: 1,
      value: 2000,
      qTipTitle:
        "Max tokens depending on the model. Prompt and completion share this limit. One token is roughly 1 English character.",
    },
  },
  model: "gpt-3.5-turbo",
};

// Action Types
const CHANGE_MODEL = "CHANGE_MODEL";
const CHANGE_TONE_OF_RESPONSE = "CHANGE_TONE_OF_RESPONSE";
const CHANGE_TONE_SETTING = "CHANGE_TONE_SETTING";

// Action Creator
export const changeModel = (model) => ({
  type: CHANGE_MODEL,
  model,
});

export const changeToneOfResponse = (toneOfResponse) => ({
  type: CHANGE_TONE_OF_RESPONSE,
  toneOfResponse,
});

export const changeToneSetting = (key, value) => ({
  type: CHANGE_TONE_SETTING,
  key,
  value,
});

export default function modelSelector(state = initialState, action) {
  let newState = JSON.parse(JSON.stringify(state));

  switch (action.type) {
    case CHANGE_MODEL:
      newState.model = action.model;
      return newState;

    case CHANGE_TONE_OF_RESPONSE:
      switch (action.toneOfResponse) {
        case "Creative":
          newState.toneOfResponse = "Creative";
          newState.toneSetting.temperature.value = 0.8;
          newState.toneSetting.topP.value = 0.9;
          newState.toneSetting.presencePenalty.value = 0.1;
          newState.toneSetting.frequencyPenalty.value = 0.1;
          newState.toneSetting.maxToken.value = 1569;
          return newState;

        case "Balanced":
          newState.toneOfResponse = "Balanced";
          newState.toneSetting.temperature.value = 0.5;
          newState.toneSetting.topP.value = 0.85;
          newState.toneSetting.presencePenalty.value = 0.2;
          newState.toneSetting.frequencyPenalty.value = 0.3;
          newState.toneSetting.maxToken.value = 1569;
          return newState;

        case "Precise":
          newState.toneOfResponse = "Precise";
          newState.toneSetting.temperature.value = 0.2;
          newState.toneSetting.topP.value = 0.75;
          newState.toneSetting.presencePenalty.value = 0.5;
          newState.toneSetting.frequencyPenalty.value = 0.5;
          newState.toneSetting.maxToken.value = 1569;
          return newState;

        case "Custom":
          newState.toneOfResponse = "Custom";
          return newState;

        default:
          return state;
      }

    case CHANGE_TONE_SETTING:
      newState.toneSetting[action.key].value = action.value;
      return newState;

    default:
      return state;
  }
}
