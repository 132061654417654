import {
  FileMarkdownOutlined,
  FilePdfOutlined,
  FileTextOutlined,
  FileWordOutlined,
  Html5Outlined,
} from "@ant-design/icons";

import React from "react";

const ExtensionIcon = ({ filename, size }) => {
  let ext;
  if (filename && (filename.startsWith("http") || filename.startsWith("https"))) ext = "html";
  else {
    ext = filename ? filename.split(".").pop() : "txt";
  }

  switch (ext) {
    case "pdf":
      return <FilePdfOutlined className="mr-3  text-red-600" style={{ fontSize: size ? size : 18 }} />;
    case "txt":
      return <FileTextOutlined className="mr-3  text-blue-600" style={{ fontSize: size ? size : 18 }} />;
    case "md":
      return <FileMarkdownOutlined className="mr-3 text-green-600" style={{ fontSize: size ? size : 18 }} />;
    case "docx":
    case "doc":
      return <FileWordOutlined className="mr-3 text-blue-600" style={{ fontSize: size ? size : 18 }} />;
    case "html":
      return <Html5Outlined className="mr-3 text-yellow-600" style={{ fontSize: size ? size : 18 }} />;
    default:
      return null;
  }
};

export default ExtensionIcon;
