import axios from "axios";

import { STATIC_URL } from "../constants/app-type";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || "http://localhost:8080";

const intialState = {
  isOpen: false,
  selectedTab: STATIC_URL,
  error: {
    status: false,
    message: "",
  },
};

// Action Types
const OPEN_CONTEXT_MODAL = "OPEN_CONTEXT_MODAL";
const CLOSE_CONTEXT_MODAL = "CLOSE_CONTEXT_MODAL";
const RAISE_CONTEXT_ERROR = "RAISE_CONTEXT_ERROR";
const CHANGE_CONTEXT_TAB = "CHANGE_CONTEXT_TAB";

// Action Creator
export const openContextModal = () => ({
  type: OPEN_CONTEXT_MODAL,
});

export const closeContextModal = () => ({
  type: CLOSE_CONTEXT_MODAL,
});

export const raiseContextError = (error) => ({
  type: RAISE_CONTEXT_ERROR,
  error,
});

export const changeContextTab = (selectedTab) => ({
  type: CHANGE_CONTEXT_TAB,
  selectedTab,
});

// Reducer
export default function contextModal(state = intialState, action) {
  let newState = { ...state };

  switch (action.type) {
    case OPEN_CONTEXT_MODAL:
      newState.isOpen = true;
      return newState;

    case CHANGE_CONTEXT_TAB:
      newState.selectedTab = action.selectedTab;
      return newState;

    case CLOSE_CONTEXT_MODAL:
      newState.isOpen = false;
      return newState;

    case RAISE_CONTEXT_ERROR:
      newState.error = action.error;
      return newState;

    default:
      return state;
  }
}

// Thunk
// export const createApp = (frontEndAppId) => {
//   return async (dispatch, getState) => {
//     const { appName, typeTab } = getState().addNewModal;

//     if (appName === "") return dispatch(raiseError({ status: true, message: "Please Input the App Name!" }));

//     dispatch(postNewApp());
//     try {
//       const { data } = await axios.post(`${BACKEND_URL}/api/apps/${frontEndAppId}`, {
//         frontEndAppId,
//         appName,
//         type: typeTab,
//       });
//       dispatch(postAppSuccess());
//       return data;
//     } catch (error) {
//       console.log("Unable to create app", error);
//       message.error("Unable to create app.", 5);
//       return dispatch(postAppFail(error));
//     }
//   };
// };

// export const clickCancel = () => {
//   return async (dispatch, getState) => {
//     const { appName, typeTab } = getState().addNewModal;

//     if (appName === "") return dispatch(raiseError({ status: true, message: "Please Input the App Name!" }));

//     dispatch(postNewApp());
//     try {
//       const { data } = await axios.post(`${BACKEND_URL}/api/apps/${frontEndAppId}`, {
//         frontEndAppId,
//         appName,
//         type: typeTab,
//       });
//       dispatch(postAppSuccess());
//       return data;
//     } catch (error) {
//       console.log("Unable to create app", error);
//       message.error("Unable to create app.", 5);
//       return dispatch(postAppFail(error));
//     }
//   };
// };
