import axios from "axios";
import { EditOutlined } from "@ant-design/icons";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Space, Table, Typography, message } from "antd";
import { useNavigate } from "react-router-dom";

const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const REACT_APP_LLM_BACKEND_URL = process.env.REACT_APP_LLM_BACKEND_URL;

const { Title, Text } = Typography;

// const [dataSource, setDataSource] = React.useState([
//   {
//     filename: "file1.docs",
//     uploadTime: "2021-01-01",
//     tags: ["FAIL"],
//   },
//   {
//     filename: "file1.txt",
//     uploadTime: "2021-01-02",
//     tags: ["IN PROGRESS"],
//   },
//   {
//     filename: "file1.pdf",
//     uploadTime: "2021-01-03",
//     tags: ["PENDING"],
//   },
//   {
//     filename: "file1.md",
//     uploadTime: "2021-01-04",
//     tags: ["READY"],
//   },
//   {
//     filename: "file1.md",
//     uploadTime: "2021-01-05",
//     tags: ["BUILDING"],
//   },
// ]);

const Datasets = () => {
  const [dataSource, setDataSource] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    let _segments = [];

    const getApps = async () => {
      try {
        let { data } = await axios.get(`${REACT_APP_BACKEND_URL}/api/apps`);
        let apps = data;

        for (let i = 0; i < apps.length; i++) {
          let app = apps[i];

          if (app) {
            let { data } = await axios.get(`${REACT_APP_LLM_BACKEND_URL}/apps/${app.appId}/segments`);
            _segments = [..._segments, ...data.results];
          }
        }

        // Extract unique pairs of appid and source
        const uniquePairs = {};
        _segments.forEach((segment) => {
          const key = `${segment.appid}-${segment.source}`;

          let found = apps.find((app) => app.appId === segment.appid);

          if (!uniquePairs[key]) {
            uniquePairs[key] = {
              appName: found.appName || "",
              appId: segment.appid,
              source: segment.source,
              uploadTime: moment(segment.created_at).toLocaleString(),
            };
          }
        });

        const result = Object.values(uniquePairs);
        result.sort((a, b) => moment(b.uploadTime).diff(moment(a.uploadTime)));

        setDataSource(result);
      } catch (error) {
        console.error(error);
        message.error("Failed to fetch datasets");
      }
    };
    getApps();
  }, []);

  // console.log(dataSource);
  const columns = [
    {
      title: "APP NAME",
      dataIndex: "appName",
      key: "appName",
    },
    {
      title: "SOURCE NAME",
      dataIndex: "source",
      key: "source",
    },
    {
      title: "UPLOAD TIME",
      dataIndex: "uploadTime",
      key: "uploadTime",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <a className="flex items-center" onClick={() => navigate(`${record.appId}`, { state: record })}>
            <EditOutlined className="mr-2" /> Edit
          </a>
          {/* <a>Delete</a> */}
        </Space>
      ),
    },
  ];

  return (
    <div className="w-full h-full p-6 text-left overflow-y-auto">
      <Title level={3}>Datasets</Title>
      <Text type="secondary">Modify the data segment to obtain the optimized outcome.</Text>

      <Table dataSource={dataSource} columns={columns} className="mt-6" />
    </div>
  );
};

export default Datasets;
