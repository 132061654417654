import React from "react";

const BotMsg = ({ message, type }) => {
  switch (type) {
    case "answer":
      return (
        <div className="flex gap-2">
          <div className="bg-sky-300 text-3xl rounded-full w-10 h-10 flex items-center justify-center">🤖</div>
          <div
            className="bg-white px-4 py-2 rounded-xl rounded-tl-none mb-6"
            style={{ maxWidth: "75%" }}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        </div>
      );

    case "reference":
      return (
        <div className="flex gap-2">
          <div className="bg-sky-300 text-3xl rounded-full w-10 h-10 flex items-center justify-center bg-transparent"></div>
          <div
            className="bg-white px-4 py-2 rounded-xl rounded-tl-none mb-6"
            style={{ maxWidth: "75%" }}
            dangerouslySetInnerHTML={{ __html: message }}
          >
            {/* {message} */}
          </div>
        </div>
      );
    default:
      return null;
  }
};

export default BotMsg;
