import React, { useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  Filler,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

import { options, generateLabels } from "./line-chart.config";

ChartJS.register(CategoryScale, Filler, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export default function LineChart({ title, data, label, subtitle }) {
  const sum = data.reduce((accumulator, value) => {
    return accumulator + value;
  }, 0);

  return (
    <div className="w-full h-fit justify-between border border-light p-6 rounded-md bg-white">
      <div className="text-slate-600 text-md mb-1">{title}</div>
      <div className="text-slate-500 text-sm">{subtitle}</div>
      <div className="text-slate-600 text-3xl py-3">{sum}</div>
      <Line
        data={{
          labels: generateLabels(),
          datasets: [
            {
              label: label,
              data: data,
              fill: true,
              borderColor: "rgba(66, 88, 255, 1)",
              backgroundColor: "rgba(66, 88, 255, 0.25)",
              lineTension: 0.3,
              cubicInterpolationMode: "monotone",
              borderWidth: 2,
              pointHoverBackgroundColor: "#fff",
              pointHoverBorderColor: "rgba(66, 88, 255, 1)",
              pointBorderWidth: 2,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 2,
              pointRadius: 4,
              pointHitRadius: 4,
            },
          ],
        }}
        options={options}
      />
    </div>
  );
}
