import { Menu } from "antd";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import items from "./sidebar.config";

function Sidebar() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const pathSplit = pathname.split("/");

  return (
    <div className="relative">
      <Menu
        onClick={(e) => navigate(e.key)}
        mode="vertical"
        align="left"
        items={items}
        className="h-full border-r border-light"
        selectedKeys={pathSplit[1]}
      />
      <div className="absolute bottom-2 left-2 text-slate-500 text-sm">v.1.0.4</div>
    </div>
  );
}

export default Sidebar;
