import axios from "axios";
import React, { useEffect } from "react";

const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL || "http://localhost:8080";

function useAppsFetcher() {
  const [apps, setApps] = React.useState([]);

  useEffect(() => {
    axios
      .get(`${REACT_APP_BACKEND_URL}/api/apps`)
      .then((res) => {
        setApps(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return apps;
}

export default useAppsFetcher;
