import React from "react";

import withIcon from "./square-icon.config";

const SquareIcon = ({ type }) => {
  return (
    <>
      {withIcon[type] && (
        <div className={`w-10 h-10 rounded-md text-3xl flex justify-center items-center ${withIcon[type].bgColor}`}>
          {withIcon[type].emoji}
        </div>
      )}
    </>
  );
};

export default SquareIcon;
