export const profileOptions = [
  {
    value: "Not Identified",
    label: "Not Identified",
  },
  {
    value: "Closed",
    label: "Closed",
  },
  {
    value: "Communicate",
    label: "Communicate",
  },
  {
    value: "Identified",
    label: "Identified",
  },
  {
    value: "Resolved",
    label: "Resolved",
  },
  {
    value: "Cancelled",
    label: "Cancelled",
  },
];
