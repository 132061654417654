import React from "react";
import { Typography } from "antd";
import { useDispatch } from "react-redux";

import { ADD_NEW } from "constants/app-type";
import { AddNewModal, AppCard } from "features/ui";
import { openModal } from "store/add-new-modal.reducer";
import { useAppsFetcher } from "pages/hooks";
import { useNavigate } from "react-router-dom";

const { Title, Text } = Typography;

const BuildApp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const apps = useAppsFetcher();

  return (
    <div className="w-full h-full bg-slate-50 p-6 text-left overflow-y-auto">
      <Title level={3}>Build Apps</Title>
      <Text type="secondary">Configure existing App or create new one.</Text>
      <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4 mt-6">
        {apps.map((app, i) => (
          <AppCard {...app} key={`build-app-${i}`} onClick={() => navigate(`/apps/${app.frontEndAppId}`)} />
        ))}
        <AppCard type={ADD_NEW} appName="Create New App" onClick={() => dispatch(openModal())}></AppCard>
      </div>
      <AddNewModal />
    </div>
  );
};

export default BuildApp;
