import React from "react";

import InputRange from "./input-range.component";
import { Form } from "antd";

const ToneSetting = ({ label, max, min, onChange, qTipTitle, step, value }) => {
  return (
    <div className={`flex justify-between items-center ${label === "Max token" ? "" : "pb-3"}`}>
      <Form.Item label={label} name={label} tooltip={qTipTitle} className="mb-0" />
      <div className="w-1/2">
        <InputRange max={max} min={min} step={step} onChange={onChange} value={value} />
      </div>
    </div>
  );
};

export default ToneSetting;
