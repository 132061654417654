import { AppLayout, MainLayout } from "layouts";
import "./App.css";

import {
  BuildApp,
  ConfigApp,
  Datasets,
  Explore,
  PageNotFound,
  Overview,
  ChatAppDoc,
  Plugins,
  DataSegment,
} from "pages";

import { Route, Routes } from "react-router-dom";
import Preview from "features/preview";

function App() {
  return (
    <Routes>
      <Route path="/" element={<MainLayout />}>
        <Route index element={<Explore />} />
        <Route path="apps" element={<BuildApp />} />
        <Route path="apps/:frontEndAppId" element={<AppLayout />}>
          <Route index element={<ConfigApp />} />
          <Route path="overview" element={<Overview />} />
          <Route path="integrations" element={<ChatAppDoc />} />
        </Route>
        <Route path="plugins" element={<Plugins />} />
        <Route path="datasets" element={<Datasets />} />
        <Route path="datasets/:appId" element={<DataSegment />} />
      </Route>
      <Route path="preview/:frontEndAppId" element={<Preview />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}

export default App;
