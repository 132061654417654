import { CHAT_APP, TEXT_GENERATOR, ADD_NEW, REVIEWER } from "constants/app-type";

const withIcon = {
  [CHAT_APP]: {
    emoji: "🤖",
    bgColor: "bg-sky-300",
  },
  [TEXT_GENERATOR]: {
    emoji: "📓",
    bgColor: "bg-emerald-300",
  },
  [REVIEWER]: {
    emoji: "🧐",
    bgColor: "bg-orange-300",
  },
  [ADD_NEW]: {
    emoji: "+",
    bgColor: "bg-teal-300",
  },
};

export default withIcon;
