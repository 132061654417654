import axios from "axios";

import { CHAT_APP } from "../constants/app-type";
import { message } from "antd";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || "http://localhost:8080";

const intialState = {
  isOpen: false,
  typeTab: CHAT_APP,
  isLoading: false,
  appName: "",
  error: {
    status: false,
    message: "",
  },
};

// Action Types
const OPEN_MODAL = "OPEN_MODAL";
const CHANGE_APP_NAME = "CHANGE_APP_NAME";
const CHANGE_APP_TYPE = "CHANGE_APP_TYPE";
const CLOSE_MODAL = "CLOSE_MODAL";
const RAISE_ERROR = "RAISE_ERROR";
const POST_NEW_APP = "POST_NEW_APP";
const POST_APP_SUCCESS = "POST_APP_SUCCESS";
const POST_APP_FAIL = "POST_APP_FAIL";

// Action Creator
export const openModal = () => ({
  type: OPEN_MODAL,
});

export const closeModal = () => ({
  type: CLOSE_MODAL,
});

export const changeAppName = (appName) => ({
  type: CHANGE_APP_NAME,
  appName,
});

export const changeAppType = (appType) => ({
  type: CHANGE_APP_TYPE,
  appType,
});

export const raiseError = (error) => ({
  type: RAISE_ERROR,
  error,
});

export const postNewApp = () => ({
  type: POST_NEW_APP,
});

export const postAppSuccess = () => {
  return {
    type: POST_APP_SUCCESS,
  };
};

export const postAppFail = (error) => ({
  type: POST_APP_FAIL,
});

// Reducer
export default function addNewModal(state = intialState, action) {
  let newState = JSON.parse(JSON.stringify(state));

  switch (action.type) {
    case OPEN_MODAL:
      newState.isOpen = true;
      return newState;

    case CHANGE_APP_NAME:
      newState.appName = action.appName;
      return newState;

    case CHANGE_APP_TYPE:
      console.log("appType", action.appType);
      newState.typeTab = action.appType;
      return newState;

    case CLOSE_MODAL:
      newState.isOpen = false;
      newState.appName = "";
      newState.typeTab = CHAT_APP;
      newState.error = {
        status: false,
        message: "",
      };
      return newState;

    case RAISE_ERROR:
      newState.error = action.error;
      return newState;

    case POST_NEW_APP:
      newState.isLoading = true;
      return newState;

    case POST_APP_SUCCESS:
      newState.isLoading = false;
      newState.isOpen = false;
      newState.appName = "";
      newState.typeTab = CHAT_APP;
      newState.error = {
        status: false,
        message: "",
      };
      return newState;

    case POST_APP_FAIL:
      newState.isLoading = false;
      newState.error = {
        status: true,
        message: action.error,
      };
      newState.typeTab = CHAT_APP;
      return newState;

    default:
      return state;
  }
}

// Thunk
export const createApp = (frontEndAppId) => {
  return async (dispatch, getState) => {
    const { appName, typeTab } = getState().addNewModal;

    if (appName === "") return dispatch(raiseError({ status: true, message: "Please Input the App Name!" }));

    dispatch(postNewApp());
    try {
      const { data } = await axios.post(`${BACKEND_URL}/api/apps/${frontEndAppId}`, {
        frontEndAppId,
        appName,
        type: typeTab,
      });
      dispatch(postAppSuccess());
      return data;
    } catch (error) {
      console.log("Unable to create app", error);
      message.error("Unable to create app.", 5);
      return dispatch(postAppFail(error));
    }
  };
};
