import React from "react";
import { Outlet } from "react-router-dom";

import { Nav, Sidebar } from "features/ui";

const MainLayout = () => {
  return (
    <div className="w-full h-full overflow-y-hidden">
      <Nav />
      <div className="flex" style={{ height: "calc(100% - 64px" }}>
        <Sidebar />
        <Outlet />
      </div>
    </div>
  );
};

export default MainLayout;
