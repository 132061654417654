import { CHAT_APP, TEXT_GENERATOR, REVIEWER } from "constants/app-type";
import { CommentOutlined, EyeOutlined, FileTextOutlined } from "@ant-design/icons";

const tabItems = [
  {
    label: (
      <span className="items-center flex">
        <CommentOutlined />
        {CHAT_APP}
      </span>
    ),
    key: CHAT_APP,
    children:
      "I want to build a chat-based application. This app uses a question-and-answer format, allowing for multiple rounds of continuous conversation.",
  },
  {
    label: (
      <span className="items-center flex">
        <FileTextOutlined />
        {TEXT_GENERATOR}
      </span>
    ),
    key: TEXT_GENERATOR,
    children:
      "I want to create an application that generates high-quality text based on prompts, such as generating articles, summaries, translations, and more.",
  },
  {
    label: (
      <span className="items-center flex">
        <EyeOutlined />
        {REVIEWER}
      </span>
    ),
    key: REVIEWER,
    children:
      "I want to create an application that reviews text based documents, such as generating articles, summaries, translations, and more.",
  },
];

export default tabItems;
