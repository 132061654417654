import { message } from "antd";
import Chat from "features/chat";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchApp } from "store/config-app.reducer";

const Preview = () => {
  const dispatch = useDispatch();

  const { frontEndAppId } = useParams();

  useEffect(() => {
    dispatch(fetchApp(frontEndAppId)).catch((error) => {
      message.error("Page not found.", 5);
      console.error("Error fetching app", error);
    });
  }, []);

  return (
    <div className="w-full h-full bg-slate-50 text-left overflow-y-auto">
      <Chat />
    </div>
  );
};

export default Preview;
