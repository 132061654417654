import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useRef } from "react";
import { Skeleton } from "antd";

import ChatInput from "./chat-input.component";
import BotMsg from "./chat-bot-msg.component";
import UserMsg from "./chat-user-msg.component";
import { sendMessage, resetChatLog } from "store/chat.reducer";
import Suggestion from "./chat-suggestion.component";

function Chat() {
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const bottomRef = useRef(null);
  const chatContainer = useRef(null);

  const { chatLog, isLoading } = useSelector((state) => state.chat);

  useEffect(() => {
    dispatch(resetChatLog());
  }, []);

  useEffect(() => {
    const lastUserMessage = Array.from(document.querySelectorAll(".user-msg:nth-last-child(1)")).pop();

    if (lastUserMessage) {
      chatContainer.current.scrollTo({
        top: lastUserMessage.offsetTop + 300,
        behavior: "smooth",
      });
    }
  }, [chatLog]);

  const onMessageSent = () => {
    const query = {
      from: "user",
      nickname: "KM",
      message: inputRef.current.value,
    };

    inputRef.current.value = "";
    dispatch(sendMessage(query));
  };

  const onKeyDown = (e) => {
    if (e.keyCode == 13 && e.shiftKey) {
      return;
    }
    if (e.key === "Enter") {
      const query = {
        from: "user",
        nickname: "KM",
        message: inputRef.current.value,
      };

      inputRef.current.value = "";
      inputRef.current.focus();
      inputRef.current.setSelectionRange(0, 0);
      dispatch(sendMessage(query));
    }
  };

  return (
    <>
      <div
        className="p-6 w-full overflow-y-scroll absolute top-0 right-0"
        style={{ height: "calc(100% - 60px)", backgroundColor: "rgba(235,238,242,.7)" }}
        ref={chatContainer}
      >
        {chatLog.map((chat, i) =>
          chat.from === "bot" ? (
            <BotMsg key={`chat-log-${i}`} {...chat} />
          ) : chat.from === "suggestion" ? (
            <Suggestion key={`chat-log-${i}`} {...chat} />
          ) : (
            <UserMsg key={`chat-log-${i}`} {...chat} />
          ),
        )}

        {isLoading && (
          <>
            <Skeleton.Avatar active={true} shape="circle" size="large" className="mr-2" />
            <Skeleton.Input active={true} size="large" />
          </>
        )}
        <div ref={bottomRef} />
      </div>

      <ChatInput onClick={onMessageSent} onKeyDown={onKeyDown} inputRef={inputRef} />
    </>
  );
}

export default Chat;
