import { CodeOutlined, DashboardOutlined, SettingOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

const getSidebarItem = (label, key, icon, children, type) => {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
};

const subMenuItems = [
  getSidebarItem(
    "",
    "overview",
    <Tooltip placement="left" title="Overview">
      <DashboardOutlined className="!text-2xl" />
    </Tooltip>,
  ),
  getSidebarItem(
    "",
    "config",
    <Tooltip placement="left" title="Configuration">
      <SettingOutlined className="!text-2xl" />
    </Tooltip>,
  ),
  getSidebarItem(
    "",
    "develop",
    <Tooltip placement="left" title="API">
      <CodeOutlined className="!text-2xl" />
    </Tooltip>,
  ),
];

export default subMenuItems;
