import { applyMiddleware, combineReducers, legacy_createStore as createStore } from "redux";
import thunk from "redux-thunk";

import addNewModal from "./add-new-modal.reducer";
import chat from "./chat.reducer";
import configApp from "./config-app.reducer";
import contextModal from "./context-modal.reducer";
import modelSelector from "./model-selector.reducer";

const rootReducer = combineReducers({
  addNewModal,
  configApp,
  modelSelector,
  chat,
  contextModal,
});

export default function configureStore() {
  const store = createStore(rootReducer, applyMiddleware(thunk));
  return store;
}
