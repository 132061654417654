import { Alert, Button, Form, Input, Modal, Typography } from "antd";
import React from "react";
import TextArea from "antd/es/input/TextArea";

const { Text } = Typography;

const AiWizard = ({ isOpen, setIsOpen }) => {
  const ok = () => setIsOpen(false);
  const handleCancel = () => setIsOpen(false);

  return (
    <>
      <Modal
        title="AI Prompt Wizard"
        open={isOpen}
        onOk={ok}
        onCancel={handleCancel}
        // width={900}
        footer={[
          <Button onClick={handleCancel}>Cancel</Button>,
          <Button type="primary" onClick={ok}>
            Generate
          </Button>,
          <Button type="primary" danger onClick={ok}>
            Apply
          </Button>,
        ]}
      >
        <Text type="secondary">Describe your scenario, I will customize a prompt for you.</Text>

        <div className="pt-6">
          <Form layout="vertical">
            <Form.Item label="Who is the intended audience?">
              <Input placeholder="e.g. Student" />
            </Form.Item>
            <Form.Item label="What problems do they hope AI can solve for them">
              <TextArea
                maxLength={1000}
                style={{ height: 160, resize: "none" }}
                onChange={() => {}}
                placeholder="e.g. Assessing academic performance"
              />
            </Form.Item>
          </Form>

          {/* <Alert
            message="I have cerated a prompt for you!"
            description="Detailed description and advice about successful copywriting."
            type="success"
            showIcon
          /> */}
        </div>
      </Modal>
    </>
  );
};

export default AiWizard;
