import { Button } from "antd";
import { BulbOutlined, RobotOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useDispatch, useSelector } from "react-redux";

import AiWizard from "features/ai-wizard";
import { changePrompt } from "store/config-app.reducer";
import { IconLabel } from "features/ui";

import { profileOptions } from "./config-app.config";

const PrompArea = () => {
  const dispatch = useDispatch();
  const [isAiWizardOpen, setIsAiWizardOpen] = useState(false);

  const { prompt, status } = useSelector((state) => state.configApp);

  return (
    <>
      <IconLabel
        classname="items-end mb-3"
        icon={<BulbOutlined className="text-yellow-500 flex items-center" />}
        label="Prefix Prompt"
      />

      <TextArea
        allowClear
        // disabled={status !== "BUILDING"}
        style={{ height: 240, resize: "none" }}
        onChange={(e) => dispatch(changePrompt(e.currentTarget.value))}
        placeholder="Prompts guide AI responses with instructions and constraints. This prompt won't be visible to users."
        value={prompt}
      />
      <div className="flex gap-2 justify-end mt-3">
        <Button
          type="primary"
          onClick={() => setIsAiWizardOpen(true)}
          icon={<RobotOutlined />}
          className="items-center flex"
        >
          AI Prompt
        </Button>
        <Select
          placeholder="Select profiles"
          style={{ width: 150 }}
          options={profileOptions}
          onChange={(value) => dispatch(changePrompt(value))}
        />
      </div>

      <AiWizard isOpen={isAiWizardOpen} setIsOpen={setIsAiWizardOpen} />
    </>
  );
};

export default PrompArea;
