import { Spin } from "antd";
import React from "react";

function LoadingScreen() {
  return (
    <Spin tip="Your app will be available soon.">
      <div className="w-full h-full absolute" />
    </Spin>
  );
}

export default LoadingScreen;
