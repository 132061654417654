import { Button, Modal } from "antd";
import { EditOutlined, ExceptionOutlined, ExclamationCircleFilled, FileTextTwoTone } from "@ant-design/icons";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ContextModal from "features/ui/context-modal";
import FileEntry from "features/ui/context-modal/context-modal-file-entry.component";
import { IconLabel } from "features/ui";
import { openContextModal } from "store/config-app.reducer";

const ContextArea = () => {
  const dispatch = useDispatch();
  const { fileList, status, dynamicUrlList, staticUrlList } = useSelector((state) => state.configApp);
  const [isConfirmReplace, setIsConfirmReplace] = useState(false);
  return (
    <>
      <IconLabel classname="mb-3" icon={<FileTextTwoTone className="text-sm flex items-center" />} label="Context" />
      <ContextModal />
      <Modal
        title="Replace Context"
        open={isConfirmReplace}
        onOk={() => {
          setIsConfirmReplace(false);
          dispatch(openContextModal());
        }}
        onCancel={() => setIsConfirmReplace(false)}
      >
        <div className="flex items-center w-full mt-5">
          <ExclamationCircleFilled style={{ color: "#faad14", fontSize: 25 }} />
          <div className="text-md text-slate-700 ml-3">Click OK to replace all the previous contexts</div>
        </div>
      </Modal>

      <div
        className="overflow-y-scroll rounded-md border px-3 relative"
        style={{ borderColor: "rgba(0, 0, 0, .25)", height: "calc(100% - 445px)", minHeight: 240 }}
      >
        {fileList.map((file, i) => (
          <FileEntry key={`file-entry-${i}`} name={file.name} index={i} />
        ))}
        {dynamicUrlList.map((url, i) => (
          <FileEntry key={`dynamic-url-${i}`} name={url} index={i} />
        ))}
        {staticUrlList.map((url, i) => (
          <FileEntry key={`static-url-${i}`} name={url} index={i} />
        ))}
        <Button
          // size="small"
          className="flex items-center absolute bottom-3 right-1"
          type="primary"
          icon={<EditOutlined />}
          onClick={() => {
            if (status === "BUILDING" || status === "EDITING") return dispatch(openContextModal());
            return setIsConfirmReplace(true);
          }}
        >
          Edit
        </Button>
      </div>
    </>
  );
};

export default ContextArea;
