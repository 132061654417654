import React from "react";
import { useDispatch } from "react-redux";
import { sendMessage } from "store/chat.reducer";

const Suggestion = ({ suggestions }) => {
  const dispatch = useDispatch();

  return (
    <div className="flex gap-2">
      <div className="bg-sky-300 text-3xl rounded-full w-10 h-10 flex items-center justify-center bg-transparent"></div>
      <div
        className="bg-white px-4 py-2 rounded-xl rounded-tl-none mb-6"
        style={{ maxWidth: "75%" }}
        // dangerouslySetInnerHTML={{ __html: message }}
      >
        <i style={{ color: "#1677ff" }}>Sugguestion:</i>

        {suggestions.map((suggestion, i) => (
          <div onClick={() => dispatch(sendMessage({ message: suggestion }))} key={`sugguestion-${i}`}>
            <a>{suggestion}</a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Suggestion;
