import { DeleteOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import ExtensionIcon from "./file-info-extension-icon.component";

function FileInfo({ filename, onDelete, index, isDeleteEnabled }) {
  const [isShowTrash, setIsShowTrash] = useState(false);

  return (
    <div
      className="w-full flex items-center py-2 px-4 rounded-lg my-2 justify-between bg-light shadow-sm"
      // style={{ backgroundColor: "rgba(235, 238, 242, 0.7)", borderBottom: "1px solid rgba(5, 5, 5, 0.06)" }}
      onMouseEnter={() => setIsShowTrash(true)}
      onMouseLeave={() => setIsShowTrash(false)}
    >
      <div className="flex overflow-hidden whitespace-nowrap text-ellipsis">
        <ExtensionIcon filename={filename} />
        <span className="flex items-center">{filename}</span>
      </div>
      {isShowTrash && isDeleteEnabled && (
        <div className="text-lg flex items-center cursor-pointer" onClick={() => onDelete(index)}>
          <DeleteOutlined />
        </div>
      )}
    </div>
  );
}

export default FileInfo;
