import axios from "axios";

const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL || "http://localhost:8080";
const REACT_APP_LLM_BACKEND_URL = process.env.REACT_APP_LLM_BACKEND_URL || "http://34.217.208.228:5000";
const NODE_ENV = process.env.REACT_APP_NODE_ENV || "development";

const initialState = {
  chatLog: [
    {
      from: "bot",
      nickname: "MM",
      message: "您好! Hello!",
      type: "answer",
    },
  ],
  isLoading: false,
  isError: false,
};

// Action Types
const POST_MESSAGE = "POST_MESSAGE";
const POST_SUCCESS = "POST_SUCCESS";
const POST_FAIL = "POST_FAIL";
const RESET_CHAT_LOG = "RESET_CHAT_LOG";

// Action Creator
const postMessage = (query) => ({
  type: POST_MESSAGE,
  query,
});

const postSuccess = (results, appType) => ({
  type: POST_SUCCESS,
  results,
  appType,
});

const postFail = () => ({
  type: POST_FAIL,
});

export const resetChatLog = () => ({
  type: RESET_CHAT_LOG,
});

// Reducer
export default function chat(state = initialState, action) {
  let newState = JSON.parse(JSON.stringify(state));

  switch (action.type) {
    case POST_MESSAGE:
      newState.isLoading = true;
      newState.chatLog.push(action.query);
      return newState;

    case POST_SUCCESS:
      newState.isLoading = false;

      if (NODE_ENV === "development") {
        newState.chatLog.push({
          from: "bot",
          nickname: "MM",
          message: action.results,
          type: "answer",
        });
      } else {
        // const { result } = action.results;
        // const parsedResult = JSON.parse(result);
        // const { answer, message, references, suggestions, error } = parsedResult;
        // const { answer, error } = parsedResult;

        if (action.appType === "Text Generator") {
          newState.chatLog.push({
            from: "bot",
            nickname: "MM",
            message: action.results,
            type: "answer",
          });

          return newState;
        }

        const { answer, message, references, suggestions, error } = action.results;

        console.log(answer, message, references, suggestions, error);

        if (answer || error) {
          newState.chatLog.push({
            from: "bot",
            nickname: "MM",
            message: answer.replaceAll("\n", "<br />") || error,
            type: "answer",
          });
        }

        if (references && references.length > 0) {
          let concatRefMessage = `<i style="color: #1677ff">Reference:</i>`;

          references.forEach((ref) => {
            concatRefMessage += `<br/>${ref}`;
          });

          newState.chatLog.push({
            from: "bot",
            nickname: "MM",
            message: concatRefMessage,
            type: "reference",
          });
        }

        if (suggestions && suggestions.length > 0) {
          newState.chatLog.push({
            from: "suggestion",
            nickname: "MM",
            suggestions,
            type: "sugguestion",
          });
        }
      }

      return newState;

    case POST_FAIL:
      newState.isLoading = false;
      newState.chatLog.push({
        from: "bot",
        nickname: "MM",
        type: "answer",
        message: "Something went wrong. Please try again later.",
      });
      return newState;

    case RESET_CHAT_LOG:
      newState.chatLog = [
        {
          from: "bot",
          nickname: "MM",
          message: "您好! Hello!",
          type: "answer",
        },
      ];
      return newState;

    default:
      return newState;
  }
}

export const sendMessage = (query) => {
  return async (dispatch, getState) => {
    let { appId, type } = getState().configApp;

    try {
      dispatch(postMessage(query));

      const formData = new FormData();
      formData.append("query", query.message);

      if (NODE_ENV === "development") formData.append("echo", 1);

      const { data } = await axios.post(`${REACT_APP_LLM_BACKEND_URL}/apps/${appId}/chat`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      axios
        .put(`${REACT_APP_BACKEND_URL}/api/apps/analytics/message-count/${appId}`)
        .then((res) => console.log("message count updated", res.data))
        .catch((err) => console.log("message count update failed", err));

      axios
        .post(`${REACT_APP_LLM_BACKEND_URL}/apps/${appId}`)
        .then((res) => {
          axios
            .put(`${REACT_APP_BACKEND_URL}/api/apps/analytics/token-usage/${appId}`, {
              tokenUsage: res.data.total_tokens,
              cost: res.data.total_cost,
            })
            .then((res) => {
              console.log("token usage updated", res.data);
            })
            .catch((err) => {
              console.log("token usage update failed", err);
            });
        })
        .catch((err) => {
          console.log("get update token from LLM failed", err);
        });

      console.log("Bot Reply", data);

      const { results } = data;

      dispatch(postSuccess(results, type));
    } catch (error) {
      console.error(error);
      dispatch(postFail());
    }
  };
};
