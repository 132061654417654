import React from "react";
import { Typography } from "antd";

import { AppCard } from "features/ui";
import { useAppsFetcher } from "pages/hooks";

const { Title, Text } = Typography;

function ExploreAppPage() {
  const apps = useAppsFetcher();

  return (
    <div className="w-full h-full bg-slate-50 p-6 text-left overflow-y-auto">
      <Title level={3}>Explore Apps by Mindlayer</Title>
      <Text type="secondary">Use these template apps instantly or customize your own apps based on the temaplates</Text>
      <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4 mt-6">
        {apps.map((app, i) => (
          <AppCard key={`explore-app-${i}`} prompt={app.prompt} appName={app.appName} type={app.type} />
        ))}
      </div>
    </div>
  );
}

export default ExploreAppPage;
