import { faker } from "@faker-js/faker";
import moment from "moment";

export const options = {
  plugins: {
    legend: {
      display: false,
    },
  },
  responsive: true,
  // maintainAspectRatio: false,
  scales: {
    y: {
      display: true,
      position: "left",
      beginAtZero: true,
      ticks: {
        stepSize: 1,
      },
    },
  },
};

export const generateLabels = () => {
  let labels = [];
  for (let i = 0; i < 7; i++) {
    const d = moment();
    labels.unshift(d.subtract(i, "days").format("MMM D, YY"));
  }
  return labels;
};

/*
const temaplateInBlue = {
  labels,
  datasets: [
    {
      label: "",
      data: [],
      fill: true,
      borderColor: "rgba(66, 88, 255, 1)",
      backgroundColor: "rgba(66, 88, 255, 0.25)",
      lineTension: 0.3,
      cubicInterpolationMode: "monotone",
      borderWidth: 2,
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "rgba(66, 88, 255, 1)",
      pointBorderWidth: 2,
      pointHoverRadius: 4,
      pointHoverBorderWidth: 2,
      pointRadius: 4,
      pointHitRadius: 4,
    },
  ],
};

const temaplateInPurple = {
  labels,
  datasets: [
    {
      label: "",
      data: [],
      fill: true,
      borderColor: "rgba(106, 41, 255, 1)",
      backgroundColor: "rgba(106, 41, 255, 0.25)",
      lineTension: 0.3,
      cubicInterpolationMode: "monotone",
      borderWidth: 2,
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "rgba(106, 41, 255, 1)",
      pointBorderWidth: 2,
      pointHoverRadius: 4,
      pointHoverBorderWidth: 2,
      pointRadius: 4,
      pointHitRadius: 4,
    },
  ],
};
*/
