import { ApiOutlined, AppstoreAddOutlined, AppstoreOutlined, DatabaseOutlined } from "@ant-design/icons";
import { LuPuzzle, LuDatabase } from "react-icons/lu";
import { GoDatabase } from "react-icons/go";
import { BsDatabase } from "react-icons/bs";
const getSidebarItem = (label, key, icon, children, type) => {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
};

const sidebarItems = [
  getSidebarItem("Explore", "", <AppstoreOutlined />),
  getSidebarItem("Build Apps", "apps", <AppstoreAddOutlined />),
  getSidebarItem("Plugins", "plugins", <LuPuzzle />),
  getSidebarItem("Datasets", "datasets", <LuDatabase />),
];

export default sidebarItems;
