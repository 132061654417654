import React, { useEffect, useState } from "react";
import { Modal, Typography, message } from "antd";
import ExtensionIcon from "features/ui/context-card/file-info-extension-icon.component";
import { EditTwoTone, EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import axios from "axios";
import TextArea from "antd/es/input/TextArea";

const { Title, Text } = Typography;

const sampleData = [
  {
    id: 1,
    filename: "file1.docs",
    segment:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt id minima nihil quasi repellendus dolor dignissimos provident modi ducimus rerum, maiores labore perferendis tempora, molestias ipsam odit reiciendis maxime alias excepturi! Excepturi animi asperiores molestias recusandae pariatur cumque, voluptatibus ipsa sed nemo sit, laudantium, adipisci itaque nesciunt facilis delectus incidunt.",
  },
  {
    id: 2,
    filename: "file1.docs",
    segment:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt id minima nihil quasi repellendus dolor dignissimos provident modi ducimus rerum, maiores labore perferendis tempora, molestias ipsam odit reiciendis maxime alias excepturi! Excepturi animi asperiores molestias recusandae pariatur cumque, voluptatibus ipsa sed nemo sit, laudantium, adipisci itaque nesciunt facilis delectus incidunt.",
  },
  {
    id: 3,
    filename: "file1.docs",
    segment:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt id minima nihil quasi repellendus dolor dignissimos provident modi ducimus rerum, maiores labore perferendis tempora, molestias ipsam odit reiciendis maxime alias excepturi! Excepturi animi asperiores molestias recusandae pariatur cumque, voluptatibus ipsa sed nemo sit, laudantium, adipisci itaque nesciunt facilis delectus incidunt.",
  },
  {
    id: 4,
    filename: "file1.docs",
    segment:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt id minima nihil quasi repellendus dolor dignissimos provident modi ducimus rerum, maiores labore perferendis tempora, molestias ipsam odit reiciendis maxime alias excepturi! Excepturi animi asperiores molestias recusandae pariatur cumque, voluptatibus ipsa sed nemo sit, laudantium, adipisci itaque nesciunt facilis delectus incidunt.",
  },
  {
    id: 5,
    filename: "file1.docs",
    segment:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore perspiciatis veritatis saepe nostrum excepturi, possimus, dignissimos provident nihil porro ab quae aspernatur quas est hic cum deleniti laborum repellendus voluptas. Praesentium officiis maxime sint vero eius. Modi laudantium repudiandae praesentium at in ab. Debitis architecto dignissimos eos itaque velit eum doloremque dolorum tempore quia recusandae deleniti, enim, dolores minima molestias voluptatem. Sequi, in reprehenderit. Soluta dolores veniam natus laudantium, numquam magni. Eveniet architecto ab tenetur ex consequuntur nemo laborum sit natus eius quae consequatur necessitatibus aperiam ipsa, quam magni qui consectetur inventore. At vel molestiae quas, ea rerum maiores dolorem.",
  },
];

const REACT_APP_LLM_BACKEND_URL = process.env.REACT_APP_LLM_BACKEND_URL;
const DataSegment = () => {
  const location = useLocation();

  const { source, appId } = location.state;

  const [segments, setSegments] = useState([]);
  const [currentSelected, setCurrentSelected] = useState(0);
  const [segmentId, setSegmentId] = useState(null);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    const getSegments = async () => {
      try {
        let { data } = await axios.get(`${REACT_APP_LLM_BACKEND_URL}/apps/${appId}/segments`);
        let _segments = data.results;

        _segments = _segments.filter((segment) => segment.source === source);
        _segments.sort((a, b) => {
          if (a.id < b.id) return -1;
          else if (a.id > b.id) return 1;
          else return 0;
        });
        setSegments(_segments);
      } catch (error) {
        message.error("Failed to fetch data segments");
        console.log(error);
      }
    };
    getSegments();
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = (i, id) => {
    setIsEdit(true);
    setCurrentSelected(i);
    setIsModalOpen(true);
    setSegmentId(id);
  };

  const showModalOnly = (i, id) => {
    setIsEdit(false);
    setCurrentSelected(i);
    setIsModalOpen(true);
    setSegmentId(id);
  };

  const handleOk = async () => {
    setIsModalOpen(false);

    if (isEdit) {
      let formData = new FormData();
      formData.append("text", segments[currentSelected].text);

      let resposne = await axios.post(`${REACT_APP_LLM_BACKEND_URL}/apps/${appId}/segments/${segmentId}`, formData);
      console.log(resposne);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="w-full h-full bg-slate-50 p-6 text-left overflow-y-auto">
      <Title level={3}>Data Segment</Title>
      <Text type="secondary">Edit your data segment to optimize the desired result.</Text>
      <div className="flex items-center pt-3">
        <ExtensionIcon filename={source} size={24} />
        <div className="text-slate-600">{source.replace("/UPLOADS/", "")}</div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4 mt-6">
        {segments.map((data, i) => (
          <div className="relative">
            <div
              className="data-segment bg-white rounded-md boder border-light cursor-pointer h-48 max-h-48 overflow-y-scroll p-6 shadow-lg hover:shadow-lg transition-all duration-300 hover:scale-105"
              key={`data-segment-${i}`}
            >
              <div className="flex justify-between items-center">
                <Text className="text-gray-500">{data.text}</Text>
              </div>
            </div>
            <EyeOutlined
              className="absolute top-10 right-4 text-xl opacity-50 hover:opacity-100 cursor-pointer transition-all z-50 "
              onClick={() => showModalOnly(i, data.id)}
            />
            <EditTwoTone
              className="absolute top-2 right-4 text-xl opacity-50 hover:opacity-100 cursor-pointer transition-all z-50"
              onClick={() => showModal(i, data.id)}
            />
          </div>
        ))}
        <Modal
          title={isEdit ? "Edit Segment" : "View Segment"}
          centered
          width={800}
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          {segments.length > 0 && (
            <TextArea
              disabled={!isEdit}
              value={segments[currentSelected].text}
              style={{ height: 400 }}
              onChange={(e) => {
                let newSegments = [...segments];
                newSegments[currentSelected].text = e.target.value;
                setSegments(newSegments);
              }}
            />
          )}
        </Modal>
      </div>
    </div>
  );
};

export default DataSegment;
