import { CheckCircleOutlined, DeleteOutlined, InboxOutlined, RightCircleOutlined } from "@ant-design/icons";
import { Alert, Button, Form, Input, Modal, Tabs } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

// import { changeAppName, changeAppType, closeModal, createApp } from "store/add-new-modal.reducer";
import TextArea from "antd/es/input/TextArea";
import {
  addFile,
  changeDynamicUrls,
  changeStaticUrls,
  changeContextTab,
  closeContextModal,
} from "store/config-app.reducer";
import Dragger from "antd/es/upload/Dragger";

import { DOCUMENT, DYNAMIC_URL, STATIC_URL } from "constants/app-type";
import FileEntry from "./context-modal-file-entry.component";
import tabItems from "./context-modal.config";

const ContextModal = () => {
  const dispatch = useDispatch();

  const { isContextModalOpen, selectedContextTab, dynamicUrls, staticUrls, fileList, status } = useSelector(
    (state) => state.configApp,
  );

  // const onCancel = () => dispatch(closeContextModal());
  const onOK = () => dispatch(closeContextModal());

  return (
    <>
      <Modal
        title="Build your application with its unique context."
        open={isContextModalOpen}
        onOk={onOK}
        width={650}
        onCancel={onOK}
        footer={[
          <Button className="flex items-center " type="primary" icon={<CheckCircleOutlined />} onClick={onOK}>
            Confirm
          </Button>,
        ]}
      >
        <div className="py-4">
          <Tabs defaultActiveKey="Chat App" items={tabItems} onChange={(tab) => dispatch(changeContextTab(tab))} />
          {selectedContextTab === STATIC_URL && (
            <TextArea
              style={{ height: 160, resize: "none" }}
              onChange={(e) => {
                dispatch(changeStaticUrls(e.currentTarget.value));
              }}
              value={staticUrls}
              placeholder="http://mindlayer.io"
            />
          )}
          {selectedContextTab === DYNAMIC_URL && (
            <TextArea
              style={{ height: 160, resize: "none" }}
              value={dynamicUrls}
              onChange={(e) => dispatch(changeDynamicUrls(e.currentTarget.value))}
              placeholder="http://mindlayer.io"
            />
          )}
          {selectedContextTab === DOCUMENT && (
            <div class="grid grid-cols-2 gap-2" style={{ height: 160 }}>
              <Dragger
                accept="application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,text/plain"
                customRequest={(e) => dispatch(addFile(e.file))}
                // disabled={status !== "BUILDING"}
                multiple={true}
                showUploadList={false}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                {/* <p className="ant-upload-text">You can import datasets as context</p>
          <p className="ant-upload-hint">Click or drag file to this area to upload</p> */}
                <p className="ant-upload-hint">You can import datasets as context</p>
              </Dragger>
              <div className="overflow-y-scroll rounded-md border border-light px-3">
                {fileList.map((file, i) => (
                  <FileEntry key={`file-entry-${i}`} name={file.name} index={i} isTrashEnable={true} />
                ))}
              </div>
            </div>
          )}
          {/* {error.status && <Alert message={error.message} type="error" showIcon className="mt-6" />} */}
        </div>
      </Modal>
    </>
  );
};

export default ContextModal;
