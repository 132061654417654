import { Button, Divider, Dropdown, Radio, Select } from "antd";
import React from "react";
import ToneSetting from "./tone-setting.component";
import { useDispatch, useSelector } from "react-redux";

import { changeModel, changeToneOfResponse, changeToneSetting } from "store/model-selector.reducer";

const { Option } = Select;

const ModelSelector = () => {
  const model = useSelector((state) => state.modelSelector.model);

  return (
    <Dropdown
      placement="bottomRight"
      trigger={["click"]}
      dropdownRender={(menu) => (
        <div className="bg-white rounded-md shadow-md">
          <CustomerDropDown />
        </div>
      )}
    >
      <Button>{model}</Button>
    </Dropdown>
  );
};

const CustomerDropDown = () => {
  const dispatch = useDispatch();

  const { toneSetting, toneOfResponse } = useSelector((state) => state.modelSelector);

  return (
    <div className="rounded-md">
      <div className="bg-slate-100 p-3 rounded-t-md">Model and Parameters</div>
      <div className="p-6">
        <div className="flex justify-between items-center">
          Model
          <Select
            defaultValue="gpt-3.5-turbo"
            style={{ width: 160 }}
            onChange={(val) => {
              dispatch(changeModel(val));
            }}
          >
            <Option value="gpt-3.5-turbo">gpt-3.5-turbo</Option>
            <Option value="gpt-3.5-turbo-16k">gpt-3.5-turbo-16k</Option>
            <Option value="gpt-4">gpt-4</Option>
            <Option value="claude-instant-1">claude-instant-1</Option>
            <Option value="claude-2">claude-2</Option>
          </Select>
        </div>
        <Divider />
        <div className="pb-3">Set tone of responses</div>
        <Radio.Group
          buttonStyle="solid"
          className="pb-6"
          onChange={(e) => dispatch(changeToneOfResponse(e.target.value))}
          value={toneOfResponse}
        >
          <Radio.Button value="Creative" className="px-6">
            Creative
          </Radio.Button>
          <Radio.Button value="Balanced" className="px-6">
            Balanced
          </Radio.Button>
          <Radio.Button value="Precise" className="px-6">
            Precise
          </Radio.Button>
          <Radio.Button value="Custom" className="px-6">
            Custom
          </Radio.Button>
        </Radio.Group>

        {Object.keys(toneSetting).map((key) => {
          return (
            <ToneSetting
              key={key}
              label={toneSetting[key].label}
              max={toneSetting[key].max}
              min={toneSetting[key].min}
              onChange={(value) => dispatch(changeToneSetting(key, value))}
              qTipTitle={toneSetting[key].qTipTitle}
              step={toneSetting[key].step}
              value={toneSetting[key].value}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ModelSelector;
