import { STATIC_URL, DYNAMIC_URL, DOCUMENT } from "constants/app-type";
import { FileTextOutlined, Html5Outlined } from "@ant-design/icons";
import { TbLink, TbUnlink } from "react-icons/tb";

const tabItems = [
  {
    label: (
      <span className="items-center flex">
        <Html5Outlined className="text-yellow-600" />
        {STATIC_URL}
      </span>
    ),
    key: STATIC_URL,
    children: <div className="text-md text-slate-600 font-medium mb-2">Extract the content from a static website.</div>,
  },
  {
    label: (
      <span className="items-center flex">
        <Html5Outlined className="text-red-600" />
        {DYNAMIC_URL}
      </span>
    ),
    key: DYNAMIC_URL,
    children: (
      <div className="text-md text-slate-600 font-medium mb-2">
        Acquire real-time data and content from a dynamic website.
      </div>
    ),
  },
  {
    label: (
      <span className="items-center flex">
        <FileTextOutlined className="text-blue-500" />
        {DOCUMENT}
      </span>
    ),
    key: DOCUMENT,
    children: (
      <div className="text-md text-slate-600 font-medium mb-2">
        Import contextual information from diverse file formats including, PDFs, Word, Text, etc.
      </div>
    ),
  },
];

export default tabItems;
