import React from "react";

import { Divider, Typography } from "antd";
import { useLocation } from "react-router-dom";

const { Title, Paragraph, Text } = Typography;
const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const generateSampleResponse = (frontEndAppId) => `{
    "appId": "${frontEndAppId}",
    "results": {
        "answer": "四連環是指在一場賽事中順序揀中第1名、第2名、第3名及第4名馬匹，毋須順序。",
        "references": [
            "四連環\n揀中一場賽事中的第1名、第2名、第3名及第4名馬匹，毋須順序\n主題網頁"
        ],
        "suggestions": [
            "我想問吓咩叫三重彩？",
            "我想問吓咩叫二重彩？",
            "我想問吓咩叫單T？"
        ]
    }
}
`;

const generateSampleRequest = (
  backendUrl,
) => `curl --location '${backendUrl}/apps/d193f6f9-4425-4df2-b38c-c1703fb75148/chat' \\
--header 'Authorization: Bearer your_access_token' \\
--form 'query="我想問吓咩叫四連環？"'
`;

const ChatAppDoc = () => {
  const { pathname } = useLocation();
  const arr = pathname.split("/");
  const frontEndAppId = arr[arr.length - 2] || "";

  return (
    <div className="w-full h-full relative">
      <div
        className="absolute w-full h-full p-6 overflow-y-scroll top-0 left-0"
        style={{ height: "calc(100% - 24px)" }}
      >
        <Title level={2}>Chat App Documentation</Title>
        <Title level={3}>Retrieve Chat Response</Title>
        <Divider />
        <Title level={4}>Description</Title>
        <Paragraph>
          <blockquote className="text-lg">Retrieves a chat response based on the provided query.</blockquote>
        </Paragraph>

        <p>
          Endpoint:{" "}
          <Text keyboard>
            {REACT_APP_BACKEND_URL}/apps/{frontEndAppId}/chat
          </Text>
        </p>

        <p>
          Method: <Text keyboard>POST</Text>
        </p>
        <p>Auth required: YES</p>
        <p>
          Content Type: <Text keyboard>multipart/form-data</Text>
        </p>

        <Title level={4}>Request Headers</Title>
        <Divider />
        <table className="border border-light mb-3">
          <tr className="text-center">
            <th className="p-3 border border-light">
              <b>Name</b>
            </th>
            <th className="p-3 border border-light">
              <b>Type</b>
            </th>
            <th className="p-3 border border-light">
              <b>Description</b>
            </th>
          </tr>
          <tr className="text-center">
            <td className="p-3 border border-light">Authorization</td>
            <td className="p-3 border border-light">string</td>
            <td className="p-3 border border-light">Bearer Token for authentication</td>
          </tr>
        </table>
        <Divider />
        <Title level={4}>Request Parameters</Title>
        <table className="border border-light mb-3">
          <tr className="text-center">
            <th className="p-3 border border-light">
              <b>Name</b>
            </th>
            <th className="p-3 border border-light">
              <b>Type</b>
            </th>
            <th className="p-3 border border-light">
              <b>Description</b>
            </th>
          </tr>
          <tr className="text-center">
            <td className="p-3 border border-light">query</td>
            <td className="p-3 border border-light">string</td>
            <td className="p-3 border border-light">The query for the chat</td>
          </tr>
        </table>
        <Divider />
        <Title level={4}>Success Response</Title>

        <p>Status Code: 200 OK</p>
        <p>Content Type: application/json</p>

        <Paragraph>
          <pre>{generateSampleResponse(frontEndAppId)}</pre>
        </Paragraph>
        <Divider />
        <Title level={4}>Example Request</Title>
        <Paragraph>
          <pre>{generateSampleRequest(REACT_APP_BACKEND_URL)}</pre>
        </Paragraph>
      </div>
    </div>
  );
};

export default ChatAppDoc;
