import React from "react";
import { SendOutlined } from "@ant-design/icons";
import { BsMic } from "react-icons/bs";
import { LuMic } from "react-icons/lu";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";

export default function ChatInput({ onClick, onKeyDown, inputRef }) {
  const [isListening, setIsListening] = React.useState(false);

  const toggleListening = () => setIsListening(() => !isListening);

  const options = {
    lang: "yue",
  };
  const { transcript, listening, resetTranscript, browserSupportsSpeechRecognition, finalTranscript } =
    useSpeechRecognition();

  const onSpeechClick = () => {
    if (isListening) {
      SpeechRecognition.stopListening();
      toggleListening();
      // console.log(transcript);
      console.log(finalTranscript);
      // const whatIsSaid = transcript.split(" ").slice(-1)[0];
      // const whatIsSaid = finalTranscript;
      const whatIsSaid = transcript;
      inputRef.current.value = inputRef.current.value + whatIsSaid;
      // resetTranscript();
    } else {
      console.log("trigger startListening");
      SpeechRecognition.startListening({
        // continuous: true,
        language: "zh-HK",
      });
      toggleListening();
    }
  };

  return (
    <div className="chatInput">
      <input
        type="text"
        id="chatInput__textarea"
        row="20"
        className="flex-1 resize-none outline-none"
        onKeyDown={onKeyDown}
        placeholder="Type here to chat"
        // type="text"
        ref={inputRef}
      />
      {browserSupportsSpeechRecognition && (
        <div className={`chatInput__micIcon ${isListening ? "listening" : ""}`} onClick={onSpeechClick}>
          <LuMic />
        </div>
      )}
      <div className="chatInput__sendIcon" onClick={onClick}>
        <SendOutlined />
      </div>
    </div>
  );
}
