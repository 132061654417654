import { InputNumber, Slider } from "antd";
import React from "react";

const InputRange = ({ max, min, onChange, step, value }) => {
  return (
    <div className="flex">
      <Slider className="w-3/5" min={min} max={max} step={step} onChange={onChange} value={value} />
      <InputNumber className="w-2/5 ml-3" min={min} max={max} step={step} onChange={onChange} value={value} />
    </div>
  );
};

export default InputRange;
