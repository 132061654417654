import Breadcrumb from "features/ui/breadcrumb";
import { Outlet } from "react-router-dom";
import React from "react";

const AppLayout = () => {
  return (
    <div className="w-full" style={{ height: "calc(100% - 104)" }}>
      <Breadcrumb />
      <Outlet />
    </div>
  );
};

export default AppLayout;
