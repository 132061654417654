import { Card } from "antd";
import React from "react";
import { Typography } from "antd";

import { SquareIcon } from "features/ui";
import withTag from "./app-card.config";

const { Title, Text } = Typography;

const AppCard = ({ appId, appName, context, frontEndAppId, prompt, status, type, onClick }) => {
  return (
    <Card
      bordered={false}
      className="w-full justify-between border border-transparent cursor-pointer hover:bg-blue-50 hover:border-blue-100 transition-colors duration-300"
      onClick={onClick}
    >
      <div className="flex items-center">
        <SquareIcon type={type} />
        <Title level={5} className="ml-2">
          {appName}
        </Title>
      </div>
      <div className="h-14 my-4 overflow-hidden overflow-ellipsis whitespace-nowrap">
        {prompt && <Text type="secondary">{prompt}</Text>}
      </div>
      <div className="flex justify-between">
        {withTag[type]} {withTag[status]}
      </div>
    </Card>
  );
};

export default AppCard;
