import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CodeOutlined, DashboardOutlined, SettingOutlined } from "@ant-design/icons";

import items from "./submenu.config";
import { Menu } from "antd";

function SubMenu() {
  const navigate = useNavigate();

  const [isHover, setIsHover] = useState(false);

  return (
    // <div className="flex flex-col w-12 h-full border-l border-light items-center">
    //   <div onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
    //     <CodeOutlined className={`text-2xl mb-3 mt-3 ease-in duration-200 cursor-pointer text-slate-600`} />
    //   </div>
    //   <div>
    //     <DashboardOutlined className="text-2xl mb-3 ease-in duration-300 text-slate-600" />
    //   </div>
    //   <div>
    //     <SettingOutlined className="text-2xl mb-3 ease-in duration-300 text-slate-600" />
    //   </div>
    // </div>\
    <Menu
      // onClick={(e) => navigate(e.key)}
      mode="vertical"
      align="center"
      items={items}
      className="h-full border-l border-light"
    />
  );
}

export default SubMenu;
