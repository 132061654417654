import { message } from "antd";
import axios from "axios";
import { STATIC_URL } from "constants/app-type";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

const generateLabels = () => {
  let labels = [];
  for (let i = 0; i < 7; i++) {
    const d = moment();
    labels.unshift(d.subtract(i, "days").format("YYYYMMDD"));
  }
  return labels;
};

const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL || "http://localhost:8080";
const REACT_APP_LLM_BACKEND_URL = process.env.REACT_APP_LLM_BACKEND_URL || "http://34.217.208.228:5000";
const NODE_ENV = process.env.REACT_APP_NODE_ENV || "development";

const initialState = {
  appId: "",
  appName: "",
  fileList: [],
  prompt: "",
  status: "BUILDING",
  url: "",
  dynamicUrls: "",
  dynamicUrlList: [],
  staticUrls: "",
  staticUrlList: [],
  urlList: [],
  type: "",
  isPreviewEnabled: false,
  isApiEnabled: false,
  isContextModalOpen: false,
  selectedContextTab: STATIC_URL,
  totalMessages: [0, 0, 0, 0, 0, 0, 0],
  tokenUsage: [0, 0, 0, 0, 0, 0, 0],
  totalCost: 0,
  contextError: {
    status: false,
    message: "",
  },
};

// Action Types
const ADD_FILE = "ADD_FILE";
const ADD_URL = "ADD_URL";
const CHANGE_PROMPT = "CHANGE_PROMPT";
const CHANGE_URL = "CHANGE_URL";
const FETCH_APP_SUCCESS = "FETCH_APP_SUCCESS";
const POST_APP = "POST_APP";
const REMOVE_FILE = "REMOVE_FILE";
const REMOVE_URL = "REMOVE_URL";
const UPDATE_STATUS = "UPDATE_STATUS";
const CHANGE_DYNAMIC_URLS = "CHANGE_DYNAMIC_URLS";
const CHANGE_STATIC_URLS = "CHANGE_STATIC_URLS";
const CLICK_CANCEL = "CLICK_CANCEL";
const OPEN_CONTEXT_MODAL = "OPEN_CONTEXT_MODAL";
const CLOSE_CONTEXT_MODAL = "CLOSE_CONTEXT_MODAL";
const RAISE_CONTEXT_ERROR = "RAISE_CONTEXT_ERROR";
const CHANGE_CONTEXT_TAB = "CHANGE_CONTEXT_TAB";
const SET_PREVIEW_ENABLED = "SET_PREVIEW_ENABLED";
const SET_API_ENABLED = "SET_API_ENABLED";
const UPDATE_FAIL = "UPDATE_FAIL";

export const openContextModal = () => ({
  type: OPEN_CONTEXT_MODAL,
});

export const closeContextModal = () => ({
  type: CLOSE_CONTEXT_MODAL,
});

export const raiseContextError = (error) => ({
  type: RAISE_CONTEXT_ERROR,
  error,
});

export const changeContextTab = (selectedContextTab) => ({
  type: CHANGE_CONTEXT_TAB,
  selectedContextTab,
});

// Action Creator
export const addFile = (file) => ({
  type: ADD_FILE,
  file,
});

export const addUrl = () => ({
  type: ADD_URL,
});

export const changePrompt = (prompt) => ({
  type: CHANGE_PROMPT,
  prompt,
});

export const changeUrl = (url) => ({
  type: CHANGE_URL,
  url,
});

export const changeDynamicUrls = (dynamicUrls) => ({
  type: CHANGE_DYNAMIC_URLS,
  dynamicUrls,
});

export const changeStaticUrls = (staticUrls) => ({
  type: CHANGE_STATIC_URLS,
  staticUrls,
});

export const clickCancel = () => ({
  type: CLICK_CANCEL,
});

export const fetchAppSuccess = (app) => ({
  type: FETCH_APP_SUCCESS,
  app,
});

export const postApp = () => ({
  type: POST_APP,
});

export const removeFile = (index) => ({
  type: REMOVE_FILE,
  index,
});

export const removeUrl = (index) => ({
  type: REMOVE_URL,
  index,
});

export const updateStatus = (appId, status, context) => ({
  type: UPDATE_STATUS,
  appId,
  status,
  context,
});

export const setPreviewEnabled = (isPreviewEnabled) => ({
  type: SET_PREVIEW_ENABLED,
  isPreviewEnabled,
});

export const setApiEnabled = (isApiEnabled) => ({
  type: SET_API_ENABLED,
  isApiEnabled,
});

export const updateFail = () => ({
  type: UPDATE_FAIL,
});

// Reducer
export default function configApp(state = initialState, action) {
  console.log("configApp", action);
  let newState = { ...state };

  switch (action.type) {
    case ADD_FILE:
      newState.fileList.push(action.file);
      return newState;

    case ADD_URL:
      newState.urlList.push(newState.url);
      newState.url = "";
      return newState;

    case CHANGE_PROMPT:
      newState.prompt = action.prompt;
      return newState;

    case CHANGE_DYNAMIC_URLS:
      let dynamicUrlList = action.dynamicUrls.split("\n");
      dynamicUrlList = dynamicUrlList.filter((url) => url !== "");
      newState.dynamicUrlList = dynamicUrlList;
      newState.dynamicUrls = action.dynamicUrls;
      return newState;

    case CHANGE_STATIC_URLS:
      let staticUrlList = action.staticUrls.split("\n");
      staticUrlList = staticUrlList.filter((url) => url !== "");
      newState.staticUrlList = staticUrlList;
      newState.staticUrls = action.staticUrls;
      return newState;

    case CHANGE_URL:
      newState.url = action.url;
      return newState;

    case CLICK_CANCEL:
      newState.fileList = [];
      newState.staticUrls = "";
      newState.dynamicUrls = "";
      return newState;

    case FETCH_APP_SUCCESS:
      const labels = generateLabels();
      const totalMessages = action.app?.analytics?.totalMessages;
      const tokenUsage = action.app?.analytics?.tokenUsage;

      let newTotalMessages = [0, 0, 0, 0, 0, 0, 0];
      let newTokenUsage = [0, 0, 0, 0, 0, 0, 0];
      let newTotalCost = 0;

      if (totalMessages && totalMessages.length > 0) {
        labels.forEach((label, index) => {
          const found = totalMessages.find((item) => item.yyyymmdd === label);
          if (found) newTotalMessages[index] = found.count;
        });
        newState.totalMessages = newTotalMessages;
      }

      if (tokenUsage && tokenUsage.length > 0) {
        labels.forEach((label, index) => {
          const found = tokenUsage.find((item) => item.yyyymmdd === label);
          if (found) {
            newTokenUsage[index] = found.count;
            newTotalCost += found.cost;
          }
        });
        newState.tokenUsage = newTokenUsage;
        newState.totalCost = newTotalCost;
      }

      newState.fileList = [];
      newState.staticUrls = "";
      newState.staticUrlList = [];
      newState.dynamicUrls = "";
      newState.dynamicUrlList = [];
      newState.appId = action.app.appId;
      newState.appName = action.app.appName;
      newState.prompt = action.app.prompt;
      newState.status = action.app.status;
      newState.type = action.app.type;
      action.app.context.forEach((item) => {
        if (item.startsWith("http") || item.startsWith("https")) newState.staticUrlList.push(item);
        else newState.fileList.push({ name: item });
      });

      return newState;

    case POST_APP:
      newState.status = "PENDING";
      return newState;

    case REMOVE_URL:
      newState.urlList.splice(action.index, 1);
      return newState;

    case REMOVE_FILE:
      newState.fileList.splice(action.index, 1);
      return newState;

    case UPDATE_STATUS:
      newState.appId = action.appId;
      newState.status = action.status;
      newState.fileList = [];
      newState.staticUrlList = [];
      newState.staticUrls = "";
      newState.dynamicUrlList = [];
      newState.dynamicUrls = "";

      if (action.context)
        action.context.forEach((item) => {
          if (item.startsWith("http") || item.startsWith("https")) newState.staticUrlList.push(item);
          else newState.fileList.push({ name: item });
        });
      return newState;

    case OPEN_CONTEXT_MODAL:
      if (newState.status !== "BUILDING") {
        newState.status = "EDITING";
        newState.fileList = [];
        newState.staticUrlList = [];
        newState.dynamicUrlList = [];
      }
      newState.isContextModalOpen = true;
      return newState;

    case CHANGE_CONTEXT_TAB:
      newState.selectedContextTab = action.selectedContextTab;
      return newState;

    case CLOSE_CONTEXT_MODAL:
      newState.isContextModalOpen = false;
      return newState;

    case RAISE_CONTEXT_ERROR:
      newState.contextError = action.error;
      return newState;

    case SET_PREVIEW_ENABLED:
      console.log("SET_PREVIEW_ENABLED", action);
      newState.isPreviewEnabled = action.isPreviewEnabled;
      return newState;

    case SET_API_ENABLED:
      console.log("SET_API_ENABLED", action.isApiEnabled);
      newState.isApiEnabled = action.isApiEnabled;
      return newState;

    case UPDATE_FAIL:
      newState.status = "BUILDING";
      return newState;

    default:
      return state;
  }
}

// Thunk
export const clickSave = (frontEndAppId) => {
  return async (dispatch, getState) => {
    const { fileList, prompt, staticUrlList, dynamicUrlList, status, appId } = getState().configApp;
    console.log(`Saving the App at ${NODE_ENV} mode with frontEndAppId`, frontEndAppId, appId);

    if (NODE_ENV === "development") {
      console.log("NODE_ENV === development");
      dispatch(postApp());

      setTimeout(async () => {
        const data = {
          appId: uuidv4(),
          status: "READY",
        };
        const updatedRes = await axios.put(`${REACT_APP_BACKEND_URL}/api/apps/${frontEndAppId}`, data);

        const { appId } = updatedRes.data;
        dispatch(updateStatus(appId, "READY"));
      }, 5000);

      return;
    }

    console.log("Step 1: Prepare the form data");
    // Step 1: Prepare the form data
    const formData = new FormData();

    console.log("prompt", prompt);

    if (prompt === "" || !prompt) formData.append("prompt", " ");
    else formData.append("prompt", prompt);

    if (status === "BUILDING" || status === "EDITING") {
      fileList.forEach((file) => formData.append("file", file));
      staticUrlList.forEach((url) => formData.append("urls[]", url));
      dynamicUrlList.forEach((url) => formData.append("dynamic_urls[]", url));
    }

    dispatch(postApp());
    console.log("Step 2: Send post app request to LLM backend");
    // Step 2: Send create app request to LLM backend

    let postAppResult;

    try {
      if (!appId) {
        console.log("Step 2.5: Build new app");
        postAppResult = await axios.post(`${REACT_APP_LLM_BACKEND_URL}/apps`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      } else {
        console.log("Step 2.5: Update existing app");
        console.log("file", formData.get("file"));
        console.log("urls[]", formData.get("urls[]"));
        console.log("dynamic_urls[]", formData.get("dynamic_urls[]"));
        console.log("prompt", formData.get("prompt"));
        postAppResult = await axios.post(`${REACT_APP_LLM_BACKEND_URL}/apps/${appId}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      }
    } catch (error) {
      message.error("Unable to save. Please try again later.", 5);
      return dispatch(updateFail());
    }

    console.log("Step 3: Post App Success", postAppResult.data);

    const interval = setInterval(async () => {
      try {
        console.log("Step 4, Keep retrieving the status update from LLM backend", postAppResult.data.id);

        // Step 3: Keep retrieving the status update from LLM backend
        const { data } = await axios.get(`${REACT_APP_LLM_BACKEND_URL}/apps/${postAppResult.data.appId}`);

        console.log("Status upadte from LLM", data);

        // Step 4: Update the status in the frontend and backend
        const updatedRes = await axios.put(`${REACT_APP_BACKEND_URL}/api/apps/${frontEndAppId}`, data);
        console.log("Update the retrieved status to the backend", updatedRes.data);

        const { appId, status, context } = updatedRes.data;
        dispatch(updateStatus(appId, status, context));

        if (data.status === "READY") {
          clearInterval(interval);
          return;
        }
      } catch (error) {
        clearInterval(interval);
        console.error(error);
        message.error("Unable to save. Please try again later.", 5);
        return;
      }
    }, 10000);
  };
};

export const fetchApp = (frontEndAppId) => {
  return async (dispatch, getState) => {
    // try {
    const { data } = await axios.get(`${REACT_APP_BACKEND_URL}/api/apps/${frontEndAppId}`);
    dispatch(fetchAppSuccess(data));
    // } catch (error) {
    //   console.error(error);
    //   message.error("Unable to fetch app. Please try again later.", 5);
    // }
  };
};
