const ADD_NEW = "Add New";
const CHAT_APP = "Chat App";
const TEXT_GENERATOR = "Text Generator";
const REVIEWER = "Reviewer";

const STATIC_URL = "Static Url";
const DYNAMIC_URL = "Dynamic Url";
const DOCUMENT = "Document";

export { ADD_NEW, CHAT_APP, TEXT_GENERATOR, REVIEWER, STATIC_URL, DYNAMIC_URL, DOCUMENT };
