import ExtensionIcon from "../context-card/file-info-extension-icon.component";
import { DeleteOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeFile } from "store/config-app.reducer";

const FileEntry = ({ name, index, isTrashEnable }) => {
  const { status } = useSelector((state) => state.configApp);
  const [isShowTrash, setIsShowTrash] = React.useState(false);
  const dispatch = useDispatch();

  return (
    <div
      className="flex w-100 justify-between items-center border-b border-light py-2 overflow-hidden whitespace-nowrap ellipsis"
      style={{ minHeight: 35 }}
      onMouseEnter={() => setIsShowTrash(true)}
      onMouseLeave={() => setIsShowTrash(false)}
    >
      <ExtensionIcon filename={name} />
      <span className="ml-2 overflow-hidden whitespace-nowrap ellipsis flex-1">{name}</span>
      <div
        className="text-lg flex items-center cursor-pointer text-slate-500"
        onClick={() => dispatch(removeFile(index))}
      >
        {isShowTrash && isTrashEnable && <DeleteOutlined />}
      </div>
    </div>
  );
};

export default FileEntry;
