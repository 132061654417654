import { Button, Divider, Tooltip, Typography } from "antd";
import ModelSelector from "features/model-selector";
import React from "react";
import { CHAT_APP, TEXT_GENERATOR } from "constants/app-type";
import withTag from "features/ui/app-card/app-card.config";
import {
  CodeTwoTone,
  DashOutlined,
  DashboardOutlined,
  DashboardTwoTone,
  SettingOutlined,
  SettingTwoTone,
} from "@ant-design/icons";

function Header({ appName, type, status }) {
  return (
    <div className="flex justify-between px-6 border-b border-light items-center" style={{ height: 68 }}>
      <div className="flex items-center">
        <div className="flex items-center justify-center p-1 bg-sky-300 rounded-lg text-4xl" style={{ maxHeight: 44 }}>
          {type === CHAT_APP ? "🤖" : type === TEXT_GENERATOR ? "📓" : "🧐"}
        </div>
        <div className="ml-3">
          <div className="text-lg font-medium text-slate-600">{appName}</div>
          <div className="flex">
            {withTag[type]}
            {withTag[status]}
          </div>
        </div>
      </div>

      <div className="flex h-full items-center">
        <ModelSelector />
        <Divider type="vertical" className="h-1/2 border-slate-200" />
        <div className="flex gap-2">
          <Button type="default" onClick={() => {}}>
            Reset
          </Button>
          <Button type="primary" onClick={() => {}}>
            Publish
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Header;
