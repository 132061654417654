import { RightCircleOutlined } from "@ant-design/icons";
import { Alert, Button, Form, Input, Modal, Tabs } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { changeAppName, changeAppType, closeModal, createApp } from "store/add-new-modal.reducer";
import tabItems from "./add-new-modal.config";

const AddNewModal = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const { appName, error, isLoading, isOpen } = useSelector((state) => state.addNewModal);

  const onAppNameChange = (e) => dispatch(changeAppName(e.currentTarget.value));
  const onTabChange = (type) => dispatch(changeAppType(type));
  const onCancel = () => dispatch(closeModal());
  const onNext = () => {
    const frontEndAppId = uuidv4();
    dispatch(createApp(frontEndAppId)).then((result) => {
      console.log("New App has successfully build", result);
      navigate(`/apps/${frontEndAppId}`);
    });
  };

  return (
    <>
      <Modal
        title="Let's begin building your new app!"
        open={isOpen}
        onOk={onNext}
        confirmLoading={isLoading}
        onCancel={onCancel}
        footer={[
          <Button onClick={onCancel}>Cancel</Button>,
          <Button
            className="flex items-center "
            type="primary"
            icon={<RightCircleOutlined />}
            loading={isLoading}
            onClick={onNext}
          >
            Next
          </Button>,
        ]}
      >
        <div className="py-4">
          <Form form={form} name="validateOnly" layout="vertical" autoComplete="off">
            <Form.Item
              name="App Name"
              label="Give your app a name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input onChange={onAppNameChange} placeholder="Enter your App Name" value={appName} />
            </Form.Item>
          </Form>

          <Tabs defaultActiveKey="Chat App" items={tabItems} onChange={onTabChange} />
          {error.status && <Alert message={error.message} type="error" showIcon className="mt-6" />}
        </div>
      </Modal>
    </>
  );
};

export default AddNewModal;
