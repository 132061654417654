import { Button, Divider, Spin, message } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import chatImage from "assets/images/chat.png";
import { clickSave, fetchApp } from "store/config-app.reducer";
import Chat from "features/chat";
import ContextArea from "./config-app-context-area.component";
import Header from "./config-app-header.component";
import PromptArea from "./config-app-prompt-area.component";

const ConfigApp = () => {
  const dispatch = useDispatch();
  const navigator = useNavigate();

  const { frontEndAppId } = useParams();
  const { appName, status, type } = useSelector((state) => state.configApp);

  useEffect(() => {
    dispatch(fetchApp(frontEndAppId)).catch((error) => {
      message.error("Page not found.", 5);
      console.error("Error fetching app", error);
      navigator("/apps");
    });
  }, []);

  return (
    <Spin
      className="!max-h-full"
      tip="Your app will be available soon."
      spinning={status === "IN PROGRESS" || status === "PENDING"}
    >
      <Header {...{ appName, status, type }} />
      <div className="flex-1 flex" style={{ height: "calc(100% - 104px)" }}>
        <div className="bg-white grid grid-cols-2 relative flex-1">
          <div
            className="bg-white h-full w-full px-6 py-6 overflow-y-scroll border-r border-light"
            // style={{ borderRight: "1px solid rgba(5, 5, 5, 0.06)" }}
          >
            <PromptArea />
            <Divider />
            <ContextArea />

            <Button
              className="w-full mt-6"
              // disabled={status !== "BUILDING"}
              type="primary"
              onClick={() => dispatch(clickSave(frontEndAppId))}
            >
              Save
            </Button>
          </div>
          <div
            className="h-full w-full relative"
            // style={{ backgroundColor: "rgba(235,238,242,.7)", paddingBottom: 60 }}
          >
            {status === "READY" ? (
              <Chat />
            ) : (
              <div
                className="flex flex-col w-full h-full justify-center items-center text-lg text-slate-700 bg-slate-50"
                // style={{ backgroundColor: "rgba(235,238,242,.7)" }}
              >
                <img src={chatImage} alt="chat" className="w-1/5 mb-6" />
                The preview will be shown upon saving the App.
              </div>
            )}
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default ConfigApp;
